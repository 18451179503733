<template>
  <div ref="canvasContainer" class="canvas-container"></div>
  <div :style="{transform: `translateX(0px)`}">
    <iframe width="240" height="160" :src="`${publicPath}gbajs/index.html`" class="iframescrat"
            :style="{transform: `translateX(-120px) translate(${x}px, ${y}px) rotate(${rotation}deg) scale(2)`}"></iframe>
  </div>
</template>

<script>
import Matter from 'matter-js';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      publicPath: process.env.BASE_URL,
      engine: null,
      render: null,
      rope: null,
      time: 0,
      topConstraint: null,
      mouseConstraint: null,
      lastBoxPosition: {
        x: 0,
        y: 0,
      },
      lastBoxRotation: 0,
      isRightHeld: false,
      isLeftHeld: false,
      isUpHeld: false,
      isDownHeld: false,
      constraints: [],
    };
  },
  computed: {
    rotation() {
      // compute position using atan2 from center
      const center = {
        x: this.windowWidth / 2,
        y: 50,
      };

      const position = {
        x: this.lastBoxPosition.x,
        y: this.lastBoxPosition.y,
      };

      const angle = Math.atan2(position.y - center.y, position.x - center.x);
      return ((angle * 180 / Math.PI) % 360) / 4 - 22.5;
    },
    x() {
      return this.lastBoxPosition.x;
    },
    y() {
      return this.lastBoxPosition.y;
    },
  },
  mounted() {
    // Create an engine
    this.engine = Matter.Engine.create();

    // Create a renderer
    this.render = Matter.Render.create({
      element: this.$refs.canvasContainer,
      engine: this.engine,
      background: 'red'
    });

    // on arrow key press
    window.addEventListener('keydown', (e) => {
      if (e.code === 'ArrowRight') {
        this.isRightHeld = true;
      }
      if (e.code === 'ArrowLeft') {
        this.isLeftHeld = true;
      }
      if (e.code === 'ArrowUp') {
        this.isUpHeld = true;
      }
      if (e.code === 'ArrowDown') {
        this.isDownHeld = true;
      }
    });

    // on arrow key release
    window.addEventListener('keyup', (e) => {
      if (e.code === 'ArrowRight') {
        this.isRightHeld = false;
      }
      if (e.code === 'ArrowLeft') {
        this.isLeftHeld = false;
      }
      if (e.code === 'ArrowUp') {
        this.isUpHeld = false;
      }
      if (e.code === 'ArrowDown') {
        this.isDownHeld = false;
      }
    });

    // Add gravity to the world
    this.engine.world.gravity.y = 1; // Adjust gravity as needed

    // Create a simple rope
    const ropeLength = 6;
    const ropeThickness = 10;
    const constraintStiffness = 0.03; // Adjust stiffness as needed
    this.rope = Matter.Composites.stack(this.windowWidth / 2, 50, 1, ropeLength, 10, 10, (x, y) => {
      let box;
      if (y === 200) box = Matter.Bodies.rectangle(x, y, ropeThickness * 2, ropeThickness * 6);
      else box = Matter.Bodies.rectangle(x, y, ropeThickness * 2, ropeThickness * 2);
      box.render.visible = false;
      return box;
    });

    const constraints = [];
    for (let i = 0; i < this.rope.bodies.length - 1; i++) {
      const bodyA = this.rope.bodies[i];
      const bodyB = this.rope.bodies[i + 1];
      const constraint = Matter.Constraint.create({
        bodyA,
        bodyB,
        stiffness: constraintStiffness,
      });
      constraints.push(constraint);
      Matter.World.add(this.engine.world, constraint);
    }
    this.constraints = constraints;

    // Pin the top rectangle
    const topBody = this.rope.bodies[0];
    this.topConstraint = Matter.Constraint.create({
      pointA: {x: topBody.position.x, y: topBody.position.y},
      bodyB: topBody,
      stiffness: constraintStiffness,
      length: 0,
    });
    Matter.World.add(this.engine.world, this.topConstraint);


    this.mouseConstraint = Matter.MouseConstraint.create(this.engine, {
      element: this.$refs.canvasContainer,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });
    Matter.World.add(this.engine.world, this.mouseConstraint);

    const lastBox = this.rope.bodies[this.rope.bodies.length - 1];
    lastBox.render.sprite.xOffset = 0.5; // Set the xOffset to center the sprite on the body

    // Add the rope to the world
    Matter.World.add(this.engine.world, this.rope);

    // Run the engine
    Matter.Engine.run(this.engine);

    window.addEventListener('resize', this.onResize);
    this.onResize();

    // Run the renderer
    Matter.Render.run(this.render);

    this.rope.bodies[3].position.x += 10;
    this.rope.bodies[5].position.y -= 10;
    this.rope.bodies[5].positionPrev.y -= 10;

    // get canvas inside .canvas-container
    const canvas = this.$refs.canvasContainer.querySelector('canvas');
    canvas.style.backgroundColor = 'transparent';

    setInterval(() => {
      this.lastBoxPosition = this.rope.bodies[this.rope.bodies.length - 1].position;
      this.lastBoxRotation = this.rope.bodies[this.rope.bodies.length - 1].angle * 180 / Math.PI;
      this.topConstraint.pointA.x += Math.sin(this.time) * 0.5;

      if (this.isRightHeld) {
        this.rope.bodies[5].position.x += 1;
      }

      if (this.isLeftHeld) {
        this.rope.bodies[5].position.x -= 1;
      }

      if (this.isUpHeld) {
        this.rope.bodies[5].position.y -= 1;
      }

      if (this.isDownHeld) {
        this.rope.bodies[5].position.y += 1;
      }

      this.time += 0.016;
    }, 16);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.render.canvas.width = this.windowWidth;
      this.render.canvas.height = this.windowHeight;

      // move top constraint to center top of screen
      this.topConstraint.pointA.x = this.windowWidth / 2;
      this.topConstraint.pointA.y = 50;

    },
    startDragging() {
      console.log('start dragging');
      this.isDragging = true;
    },
    stopDragging() {
      console.log('stop dragging');
      this.isDragging = false;
    },
  },
};
</script>

<style scoped>
.canvas-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.iframescrat {
  position: fixed;
  top: 0;
  margin: 0 auto;
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #2d2d33;
  z-index: 105 !important;
}
</style>