<template>
  <SpringPhysicsCanvas/>
  <a href="https://glumbo.co/">
    <div class="container__glumbo-logo">
      <img src="@/assets/glumbo.gif" alt="glumbo logo" class="glumbo" width="100"/>
      <div class="container__glumbo-text">
        <img src="@/assets/glumbotextt2.png" alt="glumbo" class="glumbotext" width="121"/>
        <img src="@/assets/corptextt2.png" alt="glumbo" class="corptext" width="121"/>
      </div>
    </div>
  </a>
  <div class="bottom__text">
    <h2>
      iceage2themeltdownforgameboyadvancedanglingfromastring.com
    </h2>
    have you ever wanted to play ice age 2 the meltdown for gameboy advance dangling from a string ?
    <br>
    now you can play it. you can play it and it is dangling from string
    <br>
    <br>
    sometimes theres scary audio so sorry about that
    <br>
    <br>
    use arrow keys to play game or sometimes move the game
    <br>
    if you press z scrat gets mad. you can also use my famous buttons such as x and enter
  </div>
  <div class="p3background"/>
</template>

<script>
import SpringPhysicsCanvas from "@/components/puzzle3/SpringPhysicsCanvas.vue";

export default {
  name: "Puzzle3Page",
  components: {SpringPhysicsCanvas},
  data() {
    return {
      sliderA: 30,
      sliderB: 0,
      sliderC: 180,
      correctArr: [false, false, false],
      clocksSolved: false,
    }
  },
  methods: {
    onClockSolved(index) {
      console.log("solved", index);
      this.correctArr[index] = true;
      if (this.correctArr.every((value) => value)) {
        this.clocksSolved = true;
      }
    },
    onClockUnsolved(index) {
      this.correctArr[index] = false;
    },
    next() {
      this.$router.push("/puzzle4");
    }
  }
}
</script>

<style scoped>
.clockwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.clockwrapper > * {
  margin: 0 10px;
}

body {
  margin: 0;
  min-height: 100vh;
  overflow: hidden;
  background-color: #1b1925 !important;
}


.p3background {
  background-color: #1b1925;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}

.sliders {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: #3d3945;
  width: fit-content;
  padding: 15px 30px;
  border-radius: 5px;
  margin: 20px auto 20px;
}

.sliders > * {
  margin: 10px 0;
  width: 50vw;
}

.scratSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.scratSection > * {
  margin: 10px 0;
}

.scratSection h2 {
  font-size: 2em;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.corptext {
  margin-top: -12px;
}

.container__glumbo-logo {
  opacity: 20%;
  padding: 10px;
  width: 250px;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  z-index: -5;
}

.container__glumbo-text {
  display: flex;
  flex-direction: column;
}

.bottom__text {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 20%;
  z-index: -10;
}
</style>
